export const openPositions = [
  {
    title: "Digital Marketing Lead",
    slug: "digital-marketing-lead",
    level: "Lead",
    type: "Full - Time",
    mode: "Hybrid Mode",
    location: "Nigeria",
    jobDescription:
      "As a Business Development Manager at Datamellon, You will identify, develop and advance relationships with clients to maximise sales. With a proven track record in selling AWS, GCP and Azure service and cloud migration, the successful candidate will work closely with delivery functions to ensure successful execution of projects and build a personal sales pipeline for long term success.",
    responsibilities: [
      "Plan and execute digital marketing strategy, including SEO/SEM, marketing database, email, social media and display advertising campaigns",
      "Identifying target audiences and devising digital campaigns to inform and engage them.",
      "Manage the company's social media accounts",
      "Measure and report the performance of all digital marketing campaigns, and assess against goals (ROI and KPIs)",
      "Keep abreast with industry and market trends and best practices",
      "Develop consumer narrative and positioning, messaging, and launch strategies for Datamellon initiatives; along with agencies and other internal and external marketing partners, produce compelling communications strategies and marketing collateral.",
      "Generate, publish, and share promotional content (news, text, photos, videos) on a daily/weekly/monthly basis across all the social and traditional media platforms.",
    ],
    qualifications: [
      "A minimum of 3-5 years of hands-on experience in digital marketing, with a focus on OEM channel partnerships or within the tech-powered on-demand delivery sector.",
      "Demonstrated understanding of the OEM channel partner landscape or on-demand delivery industry, including key trends, competitors, and customer behaviors.",
      "Proven ability to develop and implement comprehensive digital marketing strategies that align with overall business objectives and contribute to brand growth.",
      "Experience in collaborating with OEM channel partners or similar industry stakeholders to drive mutually beneficial marketing initiatives.",
      "Strong analytical skills with the ability to interpret data, analyze campaign performance, and make data-driven decisions for continuous improvement.",
      "Familiarity with technology-driven marketing tools, platforms, and analytics, including but not limited to SEO, SEM, social media, and marketing automation.",
      "Proficiency in creating compelling and engaging digital content, understanding the nuances of content marketing within the specified industry.",
      "Experience in managing digital marketing budgets effectively, ensuring optimal allocation for maximum impact.",
      "Proven ability to collaborate with cross-functional teams, including sales, product development, and other relevant departments, to ensure alignment and integration of marketing efforts.",
      "A forward-thinking and innovative mindset, staying abreast of industry trends and emerging technologies to bring fresh ideas to the marketing strategy.",
      "Excellent written and verbal communication skills, with the ability to convey complex concepts clearly and persuasively.",
    ],
  },

  {
    title: "AI Engineer",
    slug: "ai-engineer",
    level: "Senior",
    type: "Full - Time",
    mode: "Hybrid Mode",
    location: "Nigeria",
    jobDescription:
    "We are seeking a highly skilled and experienced AI Engineer to join our innovative team. As an AI Engineer with Generative AI expertise, you will play a pivotal role in developing and implementing cutting-edge Generative AI solutions that will revolutionize our products and services. You will collaborate closely with a team of engineers, scientists, and product managers to push the boundaries of Generative AI and drive business growth.",
    responsibilities: [
      "Develop and implement Generative AI models using deep learning frameworks like TensorFlow, PyTorch, or JAX",
      "Design and build Generative AI pipelines and infrastructure on cloud platforms such as AWS, Google Cloud, and Azure",
      "Conduct extensive data analysis and prepare high-quality data for Generative AI models",
      "Evaluate and refine Generative AI models to achieve optimal performance and accuracy",
      "Stay abreast of the latest Generative AI research and advancements",
      "Collaborate with cross-functional teams to integrate Generative AI solutions into our products and services",
      
    ],
    qualifications: [
      "Master's degree in Computer Science, Artificial Intelligence, or a related field",
      "5+ years of hands-on experience in developing and implementing machine learning models",
      "Demonstrated proficiency in deep learning frameworks, including TensorFlow, PyTorch, or JAX",
      "In-depth understanding of Generative AI techniques, such as generative adversarial networks (GANs), variational autoencoders (VAEs), and diffusion models",
      "Solid knowledge of cloud platforms like AWS, Google Cloud, or Azure for deploying and managing AIML solutions",
      "Excellent problem-solving and analytical skills",
      "Ability to work independently and as part of a team in a fast-paced environment",
    ],
  },

  {
    title: "Business Development Manager - Ghana",
    slug: "sales-manager",
    level: "Manager",
    type: "Full - Time",
    mode: "Hybrid Mode",
    location: "Ghana",
    jobDescription:
      "As a Business Development Manager at Datamellon, You will identify, develop and advance relationships with clients to maximise sales. With a proven track record in selling AWS, GCP and Azure service and cloud migration, the successful candidate will work closely with delivery functions to ensure successful execution of projects and build a personal sales pipeline for long term success.",
    responsibilities: [
      "Increase sales pipeline, from lead sourcing to account management",
      "Present (new) services and enhance existing relationships",
      "Generate leads by cold calling prospective clients, confirm meetings and following up marketing campaigns",
      "Identify opportunities for campaigns that will lead to an increase in sales",
      "Forecast sales targets, submit monthly progress reports and ensure that the data is accurately entered and managed within the company's CRM system",
      "Track, record and report activity on accounts            ",
      "Using knowledge of the market and competitors, identify and develop the company's unique selling propositions and differentiations",
      "Gravitas to liaise with C-level executives operating in large, international blue chip organisations across all business sectors",
    ],
    qualifications: [
      "Proven track record of cloud and hyper-scaler services sales",
      "Wealth of existing connections in the industry would be advantageous",
      "Successful track record of at least 3 years B2B sales experience",
      "Be a new business winner as well as an experienced account manager",
      "Proven ability to create, negotiate and manage long term relationships",
      "Ability to identify client issues/needs and translate into compelling solutions to address clients' requirements",
      "Ability to produce compelling presentations",
      "Ability to engage, motivate, influence and negotiate at all levels",
      "Excellent commercial and relationship negotiation skills and business acumen",
      "Highly motivated self-starter, ready to hit the ground running",
      "Ability to identify cross-selling opportunities",
      "Degree level educated, preferably in business/management",
    ],
  },
  {
    title: "Senior Developer",
    slug: "senior-developer",
    level: "Senior",
    type: "Full - Time",
    mode: "Hybrid Mode",
    location: "Lagos",
    jobDescription:
      "As a Senior Developer at Datamellon, you will play a pivotal role in architecting, designing, and implementing solutions that push the boundaries of what technology can achieve. Leveraging your expertise, you will contribute to the development of cutting-edge projects and mentor junior team members, fostering a culture of continuous learning and excellence.",
    responsibilities: [
      "Write clean, efficient, and well-documented code",
      "Design and develop new features for web and mobile applications",
      "Deploying and managing servers, hosting, and cloud services like AWS, Azure, or Google Cloud Platform for application deployment.",
      "Conduct thorough testing of software components, support deployment processes, and ensure smooth implementation.",
      "Create technical documentation, including design specifications, system architecture, and user guides.",
      "Designing, implementing, and maintaining databases to ensure efficient data storage and retrieval.",
      "Mentoring junior developers and fostering a culture of continuous learning and growth.",
      "Stay updated with industry trends, emerging technologies, and best practices.",
    ],
    qualifications: [
      "Bachelor's/Master’s degree in Computer Science, Engineering, or related field.",
      "Minimum of 5 years experience in software development.",
      "Strong experience with JavaScript, HTML, and CSS",
      "Experience with front-end frameworks such as React or Angular",
      "Strong experience with at least one back-end programming language such as Node.js, Python, or Ruby",
      "Experience with relational databases such as MySQL or PostgreSQL",
      "Experience with version control systems such as Git",
      "Experience with Agile development methodologies",
      "Strong problem-solving and critical thinking skills",
      "Strong communication skills and ability to work in a team environment",
      "Proven track record of successful project delivery and leadership in an Agile environment.",
      "AWS Solution Architect Associate certification will be a good advantage.",
    ],
  },
  {
    title: "Finance Analyst",
    slug: "finance-analyst",
    level: "Senior",
    type: "Full - Time",
    mode: "Hybrid Mode",
    location: "Lagos",
    jobDescription:
      "As a Finance Analyst at Datamellon, you will play a crucial role in supporting financial decision-making by collecting, analyzing, and interpreting financial data. You will work closely with cross-functional teams to provide insights, forecast trends, and contribute to the overall financial health of the organization.",
    responsibilities: [
      "Develop and execute the financial strategy and models to support our business objectives and long-term growth plans.",
      "Lead financial planning, budgeting, forecasting, and analysis to drive informed decision-making.",
      "Monitor and analyze financial performance, providing key insights and recommendations to optimize business outcomes for cost optimization and revenue generation.",
      "Oversee financial operations, including accounting, tax planning, cash flow management, and risk assessment.",
      "Collaborate with cross-functional teams to evaluate investment opportunities and support strategic initiatives.",
      "Implement robust internal controls, reporting standards, risk management processes, and corporate governance practices",
      "Manage relationships with financial partners, investors, and other stakeholders.",
      "Provide guidance and mentorship to the finance team, fostering a culture of excellence and continuous improvement.",
    ],
    qualifications: [
      "Bachelor's degree in finance, accounting, or a related field, M.Sc/MBA is preferred.",
      "Minimum of 5 years proven experience as a Financial Analyst or in a senior finance leadership role, preferably in the IT industry or Tech startups.",
      "Strong financial acumen with a track record of driving financial performance and supporting business growth.",
      "Proficiency in financial software and systems.",
      "In-depth knowledge of financial planning, analysis, budgeting, and reporting.",
      "Proven ability to manage relationships with external stakeholders, including investors, auditors, and financial institutions.",
      "Must have relevant professional certification - e.g ACCA, ICAN",
    ],
  },
  {
    title: "Product Manager – AI & Data Solutions",
    slug: "product-manager-ai-data-solutions",
    level: "Mid-Level Product Manager",
    type: "Full - Time",
    mode: "Hybrid Mode",
    location: "Lagos",
    jobDescription:
      "As a Product Manager at Datamellon, you will be instrumental in driving the success of our product lines. You will lead a talented cross-functional team, guiding them through the entire product lifecycle, from ideation to market launch and beyond. Your strategic vision, coupled with a deep understanding of customer needs and industry trends, will be critical in shaping the direction of our products.",
    responsibilities: [
      "Define and communicate the product vision, strategy, and roadmap in alignment with company goals.",
      "Conduct in-depth market and customer research to identify opportunities and validate product ideas.",
      "Drive the product development process, from requirements gathering to launch and iteration.",
      "Work closely with engineering, design, marketing, and sales to ensure alignment and successful execution.",
      "Analyze product metrics, customer feedback, and market trends to inform product iterations.",
      "Advocate for your products internally and externally, building excitement and adoption.",
    ],
    qualifications: [
      "Bachelor's degree in Business, Engineering, Computer Science, or a related field. M.Sc/MBA is preferred.",
      "3+ years of experience in product management, preferably in AI, data analytics, or SaaS products.",
      "Proven track record of successfully launching and managing innovative products.",
      "Excellent analytical and problem-solving skills with a data-driven mindset.",
      "Strong communication and collaboration abilities across technical and non-technical teams.",
      "Knowledge of machine learning and AI concepts",
      "Experience with cloud platforms (e.g., AWS, Azure, GCP)",
      "Nice to have: MBA or relevant product management certifications",
    ],
  },
  {
    title: "Product Lead",
    slug: "product-lead",
    level: "Senior",
    type: "Full - Time",
    mode: "Hybrid Mode",
    location: "Lagos",
    jobDescription:
      "As a Product Lead at Datamellon, you will be instrumental in driving the success of our product lines. You will lead a talented cross-functional team, guiding them through the entire product lifecycle, from ideation to market launch and beyond. Your strategic vision, coupled with a deep understanding of customer needs and industry trends, will be critical in shaping the direction of our products.",
    responsibilities: [
      "Lead Datamellon’s Product development process by collaborating closely with the development team to ensure successful execution.",
      "Developing and executing the product strategy aligned with the company's overall goals and market needs.",
      "Implement testing and quality assurance procedures to guarantee reliability and efficiency.",
      "Develop go-to-market strategies, including marketing campaigns, sales enablement, and customer training, to successfully launch new product features.",
      "Engaging with stakeholders, including customers, sales, marketing, and senior management, to gather requirements and align strategies.",
      "Conducting market research, gathering feedback, and analyzing data to identify market trends, customer needs, and competitive positioning.",
      "Creating and managing the product roadmap, setting priorities, timelines, and milestones for product releases and enhancements.",
      "Manage the budget and resources effectively, including cost estimation, resource allocation, and ROI analysis.",
      "Engaging with stakeholders, including customers, sales, marketing, and senior management, to gather requirements and align strategies.",
      "Maintaining a strong customer-centric approach, understanding user needs, and championing a user-friendly product experience.",
      "Identifying and mitigating risks associated with the product, ensuring compliance with industry standards and regulations.",
      "Directing and motivating product teams, ensuring clear communication, and fostering a collaborative work environment.",
    ],
    qualifications: [
      "Bachelor's degree in Business, Engineering, Computer Science, or a related field. M.Sc/MBA is preferred.",
      "Proven experience as a Product Lead/Manager for minimum of 3 years",
      "Strong strategic planning and product development skills.",
      "Exceptional organizational and problem-solving abilities.",
      "Excellent communication and interpersonal skills.",
    ],
  },
 
  {
    title: "Tech sales Associates",
    slug: "tech-sales-associates",
    level: "Mid-Level",
    type: "Full - Time",
    mode: "Hybrid Mode",
    location: "Lagos",
    jobDescription:
      "As a Tech Sales Associate at Datamellon, you will play a crucial role in connecting our customers with cutting-edge technology solutions that meet their needs. You'll be the face of our company, providing expert advice, building relationships, and ensuring an exceptional customer experience.",
    responsibilities: [
      "Sales and promotion of Datamellon products and services. ",
      "Generate leads and establish relationships with key decision-makers and other high-value prospects. ",
      "Make targeted sales proposals, educate clients on the products by working closely with developers, marketing, and other internal teams.",
      "Establishing the technical needs of the customer and suggesting appropriate solutions.",
      "Keeping customers informed of the latest technological trends and innovations.",
      "Ensuring that sales targets are consistently surpassed.",
    ],
    qualifications: [
      "Two to four years of relevant software/hardware sales and marketing experience with a successful OEM channel partner.",
      "Proven track record of meeting and exceeding sales goals and targets",
      "Outstanding experience in commercial client acquisition and relationship development",
      "Ability to generate leads and actively seek out new sales opportunities through cold calling, networking, and social media.",
      "Ability to articulate niche or platform-specific target product/service opportunities to customers and evolve solutions rather than product features.",
      "Good knowledge of Hyperscalers such AWS, GCP, Azure and OCI.",
      "AWS cloud practitioner certification will be a good advantage.",
      "Tenacious and persistent – a “won’t give up on a sale until the answer is yes” attitude",
    ],
  },
 
];
